<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>
        <!-- <br /> -->
        <!-- <v-layout>
          <v-flex class="pr-1 pl-1">
            <v-card outlined  v-ripple>
              <v-card-title  class="pr-0 text-center">One Box Dashboard</v-card-title>
            </v-card>
          </v-flex>
        </v-layout> -->
        <v-list nav :color="color.BG" class="pb-6">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-monitor-dashboard</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">&nbsp;{{ $t("admin.dashboard") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- <br /> -->
        <v-card class="elevation-0" :color="color.BG" id="thiscontainerdashboardadmin">
        <!-- <div v-if="loaddataprogress === true">
          <v-flex lg12 xs12 class="ma-2 ml-4 mr-4" >
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
        </div> -->
        <!-- หน้าจอโทรศัพท์ -->
        <div v-if="resolutionScreen<500">
          <v-flex class="pr-2 pl-1">
            <v-card outlined >
              <v-card-title class="pr-0 "><v-icon class="pr-1" large>location_city</v-icon>{{ $store.getters.dataTypeusercurrent($store.state.account_active['type'],$t('default')) }}</v-card-title>
              <v-card-text class="pr-0 pt-3 text--primary" >
                <div>{{ $t("admin.numberuser") }}<span class="text-h5">{{ total_person }}</span></div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex class="pr-2 pl-1 pt-1">
            <v-card outlined>
              <v-card-title><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t("admin.totalusage") }}</span></v-card-title>
              <v-card-text >              
                <v-progress-linear v-if="percentstorage <= 70" :color="color.theme" height="18" :value="percentstorage"><strong  class="caption font-weight-light">{{ Math.round(percentstorage) }}%</strong></v-progress-linear>
                <v-progress-linear v-else-if="percentstorage <= 90 && percentstorage > 70" color="amber" height="18" :value="percentstorage"><strong  class="caption font-weight-light">{{ Math.round(percentstorage) }}%</strong></v-progress-linear>
                <v-progress-linear v-else-if="percentstorage > 90" color="red" height="18" :value="percentstorage"><strong  class="caption font-weight-light">{{ Math.round(percentstorage) }}%</strong></v-progress-linear>
                <div class="pt-1 ">{{ used_storage }} in {{ total_storage }}</div>
              </v-card-text> </v-card
          ></v-flex>
          <v-flex class="pr-2 pl-1 pt-1">
            <v-card outlined>
              <v-card-title class="pr-0"><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t("admin.spacestorage") }}</span></v-card-title>
              <v-card-text class="pr-0">
                <v-row class="pt-0 pb-0"
                  ><v-col class="pt-0 pb-0 text--primary" >{{ $t("admin.available") }}
                    <v-icon small :color="percent_remain >= 70 ? 'green':percent_remain < 70 && percent_remain > 10 ? 'amber':'red'">mdi-circle-medium</v-icon>
                    </v-col><v-col class="pt-0 pb-0 text--primary">{{ $t("admin.driveused") }}
                      <v-icon small :color="percentstorage <=70 ? 'green':percentstorage > 70 && percentstorage < 90 ? 'amber':'red'">mdi-circle-medium</v-icon>
                      </v-col><v-col class="pt-0 pb-0 text--primary">{{ $t("admin.drivetash") }}
                    <v-icon small :color="percent_trash <=70 ? 'green':percent_trash > 70 && percent_trash < 90 ? 'amber':'red'">mdi-circle-medium</v-icon></v-col></v-row
                >
                <v-row class="pt-0 pb-0"
                  ><v-col class="pt-0 pb-0">{{ remain_storage }}</v-col
                  ><v-col class="pt-0 pb-0">{{ used_storage }}</v-col
                  ><v-col class="pt-0 pb-0">{{ drive_tash }}</v-col></v-row
                >
              </v-card-text>
            </v-card></v-flex >
            <v-flex class="pt-1">
            <v-card outlined class="mr-2 ml-1"> 
              <v-card-title class="pr-0"><v-icon class="pr-1" large>mdi-chart-bar</v-icon><span>{{ $t("admin.storageused") }}</span></v-card-title>
              <v-card-text> 
                 <!-- <v-row>  -->
                  <v-col >  
                  <!-- <v-row class="pt-0 pb-0" ><v-col class="pt-0 pb-0" >Total Files and Folder</v-col><v-col class="pt-0 pb-0">Files</v-col><v-col class="pt-0 pb-0">Folder</v-col></v-row>
                  <v-row class="pt-0 pb-0" ><v-col class="pt-0 pb-0 ">{{total_filefolder}}</v-col><v-col class="pt-0 pb-0">{{total_file}}</v-col><v-col class="pt-0 pb-0">{{total_folder}}</v-col></v-row> -->
                  <!-- <v-row class="pt-0 pb-0" > -->
                    <v-row class="pt-0 pb-0 pl-12 ml-3" ><v-col class="pt-0 pb-0">{{ $t("admin.file") }}</v-col><v-col class="pt-0 pb-0">{{ $t("admin.folder") }}</v-col><v-col class="pt-0 pb-0"></v-col><v-col class="pt-0 pb-0"></v-col></v-row>
                    <v-row class="pt-0 pl-12 ml-3" ><v-col class="pt-0 pb-0">{{total_file}}</v-col><v-col class="pt-0 pb-0">{{total_folder}}</v-col><v-col class="pt-0 pb-0"></v-col><v-col class="pt-0 pb-0"></v-col></v-row> 
                    <v-row class="pt-0 pb-0">
                     <v-col class="pt-0 pb-0 pl-0 pr-0">                  
                  <div class="text-center"  v-if="loaddataprogress_2 === true" >
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <v-skeleton-loader                         
                              class="mx-auto pt-2"
                              type="card-avatar"
                          ></v-skeleton-loader>
                  </div>
                  <div id="chartFile" v-else>
                    <apexchart width="70%" type="pie" :options="$t('default') === 'th'? chartOptionsFile_th:chartOptionsFile" :series="seriesFile"></apexchart>
                  </div>
                  </v-col>
                  </v-row> 
                  </v-col>
                  <v-col > 
                    <v-row class="pt-0 pb-0" ><v-col class="pt-0 pb-0" >{{ $t("admin.personalfile") }}</v-col><v-col class="pt-0 pb-0">{{ $t("admin.inbox") }}</v-col><v-col class="pt-0 pb-0">{{ $t("admin.outbox") }}</v-col><v-col class="pt-0 pb-0">{{ $t("admin.sharedrive") }}</v-col></v-row>
                  <v-row class="pt-0 " ><v-col class="pt-0 pb-0" >{{show_em_use}}</v-col><v-col class="pt-0 pb-0">{{show_inbox_use}}</v-col><v-col class="pt-0 pb-0">{{show_outbox_use}}</v-col><v-col class="pt-0 pb-0">{{show_share_use}}</v-col></v-row> 
                  <v-row class="pt-0 pb-0" > 
                    <v-col class="pt-0 pb-0 pl-0 pr-0"> 
                       <div class="text-center"  v-if="loaddataprogress_2 === true" >
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <v-skeleton-loader                         
                              class="mx-auto pt-2"
                              type="card-avatar"
                          ></v-skeleton-loader>
                  </div>
                    <div id="chart"  v-else>
                    <apexchart width="70%"  type="pie" :options="$t('default') === 'th'? chartOptions_th :chartOptions" :series="series"></apexchart>
                  </div> 
                    </v-col>
                  </v-row>
                   </v-col>
                <!-- </v-row> -->
              </v-card-text>
            </v-card>
          </v-flex>   
          <!-- <v-flex class="pr-2 pl-1 pt-1">
            <v-card outlined height="455">
              <v-card-title class="pr-0 "><v-icon class="pr-1" large>domain</v-icon><span>&nbsp;{{ $t("admin.top5department") }}</span></v-card-title>
              <v-card-text>
                <div class="text-center"  v-if="loaddataprogress_3 === true" > -->
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <!-- <v-skeleton-loader                         
                              class="mx-auto"
                              type="table"
                              max-height="345"
                          ></v-skeleton-loader> -->
                  <!-- </div>
                  <div v-else>
                <v-list dense v-if=" topdepart.length !== 0" >
                  <v-list-item v-for="item in topdepart" :key="item.title" dense >
                    <v-list-item-avatar v-text="item.list"></v-list-item-avatar>
                    <v-list-item-content class="pt-0 pb-0 ">
                      <v-list-item-title ><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"><v-col hide-detail class="pt-0 pb-0 ">{{item.department_name}}</v-col><v-col hide-detail class="pt-0 pb-0 "><p class="text--secondary text-right mb-0">{{item.storage_department}}</p></v-col></v-row></v-list-item-title>                   
                      <v-list-item-subtitle> -->
                        <!-- <v-progress-linear :color="color.theme" height="4" :value="item.percent_storage"></v-progress-linear> -->
                        <!-- <v-progress-linear v-if="item.percent_storage <= 70 " :color="color.theme" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage <= 90 && item.percent_storage > 70" color="amber" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage > 90" color="red" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        </v-list-item-subtitle>                      
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                 <v-list v-else-if="loaddataprogress_3 === false && topdepart.length === 0" >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('dashboard.deempty')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list></div>
              </v-card-text>
              <v-card-actions class="pt-0">
               <v-spacer></v-spacer>
               <v-btn :disabled="topdepart.length === 0" :color='color.theme' :dark="color.darkTheme" x-small @click="openseeall = true,checkshowtable='d'">{{ $t("admin.seeall") }}</v-btn>
               </v-card-actions> 
            </v-card>
          </v-flex>  -->
          <v-flex class="pr-2 pl-1 pb-2 pt-1">
            <v-card outlined height="430">
              <v-card-title class="pr-0"><v-icon class="pr-1" large>mdi-account</v-icon><span>&nbsp;{{ $t("admin.top5employee") }}</span></v-card-title>
              <v-card-actions class="pt-0">
               <v-spacer></v-spacer>
                <!-- <v-btn :disabled="topperson.length === 0" v-if="sharedrive === true"  :color='color.theme' :dark="color.darkTheme" x-small @click="openseeall = true,checkshowtable='e'">{{ $t("admin.seeall") }}</v-btn> -->
               <v-btn class="mr-6" :disabled="toppersonprivate.length === 0"  :color='color.theme' :dark="color.darkTheme" small @click="openseeall = true,checkshowtable='e'">{{ $t("admin.seeall") }}</v-btn>
               </v-card-actions> 
              <v-card-text>
                <div class="text-center"  v-if="loaddataprogress_4 === true" >
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <v-skeleton-loader                         
                              class="mx-auto"
                              type="table"
                              max-height="340"
                          ></v-skeleton-loader>
                  </div>
                <!-- <v-tabs v-model="tab" :color="color.theme" v-else>
                    <v-tabs-slider :slider-color="color.theme" ></v-tabs-slider>
                    <v-tab :color="color.theme" @click="sharedrive = true">
                      {{$t('admin.sharedrive')}}
                    </v-tab>
                    <v-tab :color="color.theme" @click="sharedrive = false">
                       {{$t('admin.personalfile')}}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab"> -->
                    <!-- ไดรฟ์ส่วนตัวของบริษัท -->
                    <!-- <v-tab-item>  -->
                <!-- <v-list dense v-if="topperson.length !== 0">
                  <v-list-item v-for="item in topperson" :key="item.title" dense >
                    <v-list-item-avatar v-text="item.list"></v-list-item-avatar>
                    <v-list-item-content class="pt-0 pb-0 ">
                      <v-list-item-title v-if="$t('default') === 'th'" ><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"><v-col hide-detail class="pt-0 pb-0 ">{{item.name_th}}</v-col><v-col hide-detail class="pt-0 pb-0 "><p class="text--secondary text-right mb-0">{{item.used_storage}}</p></v-col></v-row></v-list-item-title>
                      <v-list-item-title v-else><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"><v-col hide-detail class="pt-0 pb-0 ">{{item.name_eng}}</v-col><v-col hide-detail class="pt-0 pb-0 "><p class="text--secondary text-right mb-0">{{item.used_storage}}</p></v-col></v-row></v-list-item-title>
                      <v-list-item-subtitle> -->
                        <!-- <v-progress-linear :color="color.theme" height="4" :value="item.percent_storage"></v-progress-linear> -->
                      <!-- <v-progress-linear v-if="item.percent_storage <= 70 " :color="color.theme" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage <= 90 && item.percent_storage > 70" color="amber" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage > 90" color="red" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                      </v-list-item-subtitle> -->
                      <!-- <v-list-item-subtitle v-text="item.used_storage"></v-list-item-subtitle> -->
                    <!-- </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="loaddataprogress_4 === false && topperson.length === 0" >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('dashboard.emempty_1')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    </v-tab-item> -->
                    <!-- ไดรฟ์ของบริษัท -->
                    <!-- <v-tab-item> -->
                      <v-list dense v-if="toppersonprivate.length !== 0">
                  <v-list-item v-for="item in toppersonprivate" :key="item.title" dense >
                    <v-list-item-avatar v-text="item.list"></v-list-item-avatar>
                    <v-list-item-content class="pt-0 pb-0 ">
                      <v-list-item-title v-if="$t('default') === 'th'" ><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"><v-col hide-detail class="pt-0 pb-0 ">{{item.name_th}}</v-col><v-col hide-detail class="pt-0 pb-0 "><p class="text--secondary text-right mb-0">{{item.used_storage}}</p></v-col></v-row></v-list-item-title>
                      <v-list-item-title v-else><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"><v-col hide-detail class="pt-0 pb-0 ">{{item.name_eng}}</v-col><v-col hide-detail class="pt-0 pb-0 "><p class="text--secondary text-right mb-0">{{item.used_storage}}</p></v-col></v-row></v-list-item-title>
                      <v-list-item-subtitle>
                        <!-- <v-progress-linear :color="color.theme" height="4" :value="item.percent_storage"></v-progress-linear> -->
                      <v-progress-linear v-if="item.percent_storage <= 70 " :color="color.theme" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage <= 90 && item.percent_storage > 70" color="amber" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage > 90" color="red" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                      </v-list-item-subtitle>
                      <!-- <v-list-item-subtitle v-text="item.used_storage"></v-list-item-subtitle> -->
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                  <v-list v-else-if="loaddataprogress_4 === false && toppersonprivate.length === 0" >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('dashboard.emempty_1')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <!-- </v-tab-item>
                  </v-tabs-items> -->
              </v-card-text>
    
            </v-card>
          </v-flex>
        </div>

        <!-- หน้าจอคอม -->
        <div v-else-if="resolutionScreen>=500">
        <v-layout>
          <v-flex lg5 class="pr-2 pl-1">
            <v-card outlined >
              <v-card-title class="pr-0 "><v-icon class="pr-1" large>location_city</v-icon>{{ $store.getters.dataTypeusercurrent($store.state.account_active['type'],$t('default')) }}</v-card-title>
              <v-card-text class="pr-0 pt-3 text--primary" >
                <div>{{ $t("admin.numberuser") }}<span class="text-h5">{{ total_person }}</span></div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex lg4 class="pr-2 pl-1">
            <v-card outlined>
              <v-card-title><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t("admin.totalusage") }}</span></v-card-title>
              <v-card-text >              
                <v-progress-linear v-if="percentstorage <= 70" :color="color.theme" height="18" :value="percentstorage"><strong  class="caption font-weight-light">{{ Math.round(percentstorage) }}%</strong></v-progress-linear>
                <v-progress-linear v-else-if="percentstorage <= 90 && percentstorage > 70" color="amber" height="18" :value="percentstorage"><strong  class="caption font-weight-light">{{ Math.round(percentstorage) }}%</strong></v-progress-linear>
                <v-progress-linear v-else-if="percentstorage > 90" color="red" height="18" :value="percentstorage"><strong  class="caption font-weight-light">{{ Math.round(percentstorage) }}%</strong></v-progress-linear>
                <div class="pt-1 ">{{ used_storage }} in {{ total_storage }}</div>
              </v-card-text> </v-card
          ></v-flex>
          <v-flex lg4 class="pr-2 pl-1">
            <v-card outlined>
              <v-card-title class="pr-0"><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t("admin.spacestorage") }}</span></v-card-title>
              <v-card-text class="pr-0">
                <v-row class="pt-0 pb-0"
                  ><v-col class="pt-0 pb-0 text--primary" >{{ $t("admin.available") }}
                    <v-icon small :color="percent_remain >= 70 ? 'green':percent_remain < 70 && percent_remain > 10 ? 'amber':'red'">mdi-circle-medium</v-icon>
                    </v-col><v-col class="pt-0 pb-0 text--primary">{{ $t("admin.driveused") }}
                      <v-icon small :color="percentstorage <=70 ? 'green':percentstorage > 70 && percentstorage < 90 ? 'amber':'red'">mdi-circle-medium</v-icon>
                      </v-col><v-col class="pt-0 pb-0 text--primary">{{ $t("admin.drivetash") }}
                    <v-icon small :color="percent_trash <=70 ? 'green':percent_trash > 70 && percent_trash < 90 ? 'amber':'red'">mdi-circle-medium</v-icon></v-col></v-row
                >
                <v-row class="pt-0 pb-0"
                  ><v-col class="pt-0 pb-0">{{ remain_storage }}</v-col
                  ><v-col class="pt-0 pb-0">{{ used_storage }}</v-col
                  ><v-col class="pt-0 pb-0">{{ drive_tash }}</v-col></v-row
                >
              </v-card-text>
            </v-card></v-flex
          >
        </v-layout>
        <br />
        <v-layout>
          <v-flex lg12>
            <v-card outlined class="mr-1 ml-1"> 
              <v-card-title class="pr-0"><v-icon class="pr-1" large>mdi-chart-bar</v-icon><span>{{ $t("admin.storageused") }}</span></v-card-title>
              <v-card-text> 
                 <v-row> 
                  <v-col class="ml-12 mr-12">  
                  <!-- <v-row class="pt-0 pb-0" ><v-col class="pt-0 pb-0" >Total Files and Folder</v-col><v-col class="pt-0 pb-0">Files</v-col><v-col class="pt-0 pb-0">Folder</v-col></v-row>
                  <v-row class="pt-0 pb-0" ><v-col class="pt-0 pb-0 ">{{total_filefolder}}</v-col><v-col class="pt-0 pb-0">{{total_file}}</v-col><v-col class="pt-0 pb-0">{{total_folder}}</v-col></v-row> -->
                  <!-- <v-row class="pt-0 pb-0" > -->
                    <v-row class="pt-0 pb-0 pl-12 ml-3" ><v-col class="pt-0 pb-0">{{ $t("admin.file") }}</v-col><v-col class="pt-0 pb-0">{{ $t("admin.folder") }}</v-col><v-col class="pt-0 pb-0"></v-col><v-col class="pt-0 pb-0"></v-col></v-row>
                    <v-row class="pt-0 pl-12 ml-3" ><v-col class="pt-0 pb-0">{{total_file}}</v-col><v-col class="pt-0 pb-0">{{total_folder}}</v-col><v-col class="pt-0 pb-0"></v-col><v-col class="pt-0 pb-0"></v-col></v-row> 
                    <v-row class="pt-0 pb-0">
                     <v-col class="pt-0 pb-0 pl-0 pr-0">
                        <div class="text-center"  v-if="loaddataprogress_2 === true" >
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <v-skeleton-loader                         
                              class="mx-auto pt-2"
                              type="card-avatar"
                          ></v-skeleton-loader>
                  </div>                  
                    <div id="chartFile" v-else>
                    <apexchart width="70%" type="pie" :options="$t('default') === 'th'? chartOptionsFile_th:chartOptionsFile" :series="seriesFile"></apexchart>
                  </div>
                  </v-col>
                  </v-row> 
                  </v-col>
                  <v-col class="ml-12 mr-12"> 
                    <v-row class="pt-0 pb-0" ><v-col class="pt-0 pb-0" >{{ $t("admin.personalfile") }}</v-col><v-col class="pt-0 pb-0">{{ $t("admin.inbox") }}</v-col><v-col class="pt-0 pb-0">{{ $t("admin.outbox") }}</v-col><v-col class="pt-0 pb-0">{{ $t("admin.sharedrive") }}</v-col></v-row>
                  <v-row class="pt-0 " ><v-col class="pt-0 pb-0" >{{show_em_use}}</v-col><v-col class="pt-0 pb-0">{{show_inbox_use}}</v-col><v-col class="pt-0 pb-0">{{show_outbox_use}}</v-col><v-col class="pt-0 pb-0">{{show_share_use}}</v-col></v-row> 
                  <v-row class="pt-0 pb-0" > 
                    <v-col class="pt-0 pb-0 pl-0 pr-0"> 
                       <div class="text-center"  v-if="loaddataprogress_2 === true" >
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <v-skeleton-loader                         
                              class="mx-auto pt-2"
                              type="card-avatar"
                          ></v-skeleton-loader>
                  </div>
                    <div id="chart" v-else >
                    <apexchart width="70%"  type="pie" :options="$t('default') === 'th'? chartOptions_th :chartOptions" :series="series"></apexchart>
                  </div> 
                    </v-col>
                  </v-row>
                   </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-flex>         
        </v-layout>
        <br />
        <v-layout>
          <!-- <v-flex lg6 class="pr-2 pl-1 pb-10 ">
            <v-card outlined height="440">
              <v-card-title class="pr-0 "><v-icon class="pr-1" large>domain</v-icon><span>&nbsp;{{ $t("admin.top5department") }}</span></v-card-title>
              <v-card-text class="pb-0">
                <div class="text-center"  v-if="loaddataprogress_3 === true" > -->
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <!-- <v-skeleton-loader                         
                              class="mx-auto"
                              type="table"
                              max-height="335"
                          ></v-skeleton-loader>
                  </div>
                  <div v-else>
                <v-list dense v-if="topdepart.length !== 0" >
                  <v-list-item v-for="item in topdepart" :key="item.title" dense >
                    <v-list-item-avatar v-text="item.list"></v-list-item-avatar>
                    <v-list-item-content class="pt-0 pb-0 ">
                      <v-list-item-title ><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"><v-col hide-detail class="pt-0 pb-0 ">{{item.department_name}}</v-col><v-col hide-detail class="pt-0 pb-0 "><p class="text--secondary text-right mb-0">{{item.used_storage}}</p></v-col></v-row></v-list-item-title>                   
                      <v-list-item-subtitle> -->
                        <!-- <v-progress-linear :color="color.theme" height="4" :value="item.percent_storage"></v-progress-linear> -->
                        <!-- <v-progress-linear v-if="item.percent_storage <= 70 " :color="color.theme" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage <= 90 && item.percent_storage > 70" color="amber" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage > 90" color="red" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        </v-list-item-subtitle>                      
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="loaddataprogress_3 === false && topdepart.length === 0" >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('dashboard.deempty')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    </div>
              </v-card-text>
              <v-card-actions > 
                <v-spacer></v-spacer>
                <v-btn :disabled="topdepart.length === 0" :color='color.theme' :dark="color.darkTheme" x-small @click="openseeall = true,checkshowtable='d'">{{ $t("admin.seeall") }}</v-btn>
                </v-card-actions>
            </v-card>
          </v-flex> -->
          <v-flex lg12 class="pr-2 pl-1 pb-10 ">
            <v-card outlined height="420">
              <v-card-title class="pr-0"><v-icon class="pr-1" large>mdi-account</v-icon><span>&nbsp;{{ $t("admin.top5employee") }}</span></v-card-title>
              <v-card-actions class="pt-0">
               <v-spacer></v-spacer>
               <!-- <v-btn :disabled="topperson.length === 0" v-if="sharedrive === true"  :color='color.theme' :dark="color.darkTheme" x-small @click="openseeall = true,checkshowtable='e'">{{ $t("admin.seeall") }}</v-btn> -->
               <v-btn class="mr-6" :disabled="toppersonprivate.length === 0" :color='color.theme' :dark="color.darkTheme" small @click="openseeall = true,checkshowtable='e'">{{ $t("admin.seeall") }}</v-btn>
               </v-card-actions> 
              <v-card-text class="pb-0">
                <div class="text-center"  v-if="loaddataprogress_4 === true">
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <v-skeleton-loader                         
                              class="mx-auto pt-2"
                              type="table"
                              max-height="300"
                          ></v-skeleton-loader>
                  </div>
                <!-- <v-tabs center-active v-model="tab" :color="color.theme" v-else>
                    <v-tabs-slider :slider-color="color.theme" ></v-tabs-slider>
                    <v-tab :color="color.theme" @click="sharedrive = true">
                      {{$t('admin.sharedrive')}}
                    </v-tab>
                    <v-tab :color="color.theme" @click="sharedrive = false">
                       {{$t('admin.personalfile')}}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab"> -->
                    <!-- ไดรฟ์ส่วนตัวของบริษัท -->
                    <!-- <v-tab-item>  -->
                <!-- <v-list dense v-if="topperson.length !== 0">
                  <v-list-item v-for="item in topperson" :key="item.title" dense >
                    <v-list-item-avatar v-text="item.list"></v-list-item-avatar>
                    <v-list-item-content class="pt-0 pb-0 ">
                      <v-list-item-title v-if="$t('default') === 'th'" >
                        <v-row hide-detail class="pt-0 pb-0 pl-0 pr-0">
                          <v-col hide-detail class="pt-0 pb-0 ">{{item.name_th}}</v-col>
                          <v-col hide-detail class="pt-0 pb-0 ">
                            <p class="text--secondary text-right mb-0">{{item.used_storage}}</p>
                            </v-col>
                            </v-row>
                          </v-list-item-title>
                      <v-list-item-title v-else>
                        <v-row hide-detail class="pt-0 pb-0 pl-0 pr-0">
                          <v-col hide-detail class="pt-0 pb-0 ">{{item.name_eng}}</v-col>
                          <v-col hide-detail class="pt-0 pb-0 ">
                            <p class="text--secondary text-right mb-0">{{item.used_storage}}</p>
                            </v-col>
                            </v-row>
                            </v-list-item-title>
                      <v-list-item-subtitle>  -->
                        <!-- <v-progress-linear :color="color.theme" height="4" :value="item.percent_storage"></v-progress-linear> -->
                      <!-- <v-progress-linear v-if="item.percent_storage <= 70 " :color="color.theme" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage <= 90 && item.percent_storage > 70" color="amber" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage > 90" color="red" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                      </v-list-item-subtitle> -->
                      <!-- <v-list-item-subtitle v-text="item.used_storage"></v-list-item-subtitle> -->
                    <!-- </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="loaddataprogress_4 === false && topperson.length === 0" >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('dashboard.emempty_1')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list> -->
                      <!-- </v-tab-item> -->
                      <!-- ไดรฟ์บริษัท -->
                    <!-- <v-tab-item> -->
                      <v-list dense v-if="toppersonprivate.length !== 0">
                  <v-list-item v-for="item in toppersonprivate" :key="item.title" dense >
                    <v-list-item-avatar v-text="item.list"></v-list-item-avatar>
                    <v-list-item-content class="pt-0 pb-0 ">
                      <v-list-item-title v-if="$t('default') === 'th'" ><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"><v-col hide-detail class="pt-0 pb-0 ">{{item.name_th}}</v-col><v-col hide-detail class="pt-0 pb-0 "><p class="text--secondary text-right mb-0">{{item.used_storage}}</p></v-col></v-row></v-list-item-title>
                      <v-list-item-title v-else><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"><v-col hide-detail class="pt-0 pb-0 ">{{item.name_eng}}</v-col><v-col hide-detail class="pt-0 pb-0 "><p class="text--secondary text-right mb-0">{{item.used_storage}}</p></v-col></v-row></v-list-item-title>
                      <v-list-item-subtitle>
                        <!-- <v-progress-linear :color="color.theme" height="4" :value="item.percent_storage"></v-progress-linear> -->
                      <v-progress-linear v-if="item.percent_storage <= 70 " :color="color.theme" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage <= 90 && item.percent_storage > 70" color="amber" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="item.percent_storage > 90" color="red" height="12" :value="item.percent_storage"><strong  class="caption font-weight-light" >{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear>
                      </v-list-item-subtitle>
                      <!-- <v-list-item-subtitle v-text="item.used_storage"></v-list-item-subtitle> -->
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="loaddataprogress_4 === false && toppersonprivate.length === 0" >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('dashboard.emempty_1')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-list-item> -->
                        <!-- <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('dashboard.emempty_2')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item> -->
                    </v-list>
                    <!-- </v-tab-item>
                  </v-tabs-items> -->
                  
              </v-card-text>

            </v-card>
          </v-flex>
        </v-layout>
        </div>
        </v-card>
      </v-card>
      <storageSeeAll
      :show="openseeall"
      @closeseeall="openseeall= false"
      :checkshowtable="checkshowtable"
      :drive_use="drive_use"
      :sharedrive="sharedrive"
      >
      </storageSeeAll>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueApexCharts from "vue-apexcharts";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
import sizeDataConverter from "../globalFunctions/sizeDataConverter";
import gbfGenerate from "@/globalFunctions/generateAuthorize";

const storageSeeAll = () => import("../components/optional/dialog-dashboard-seeall.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
    components:{
     onebox_admin_toolbar,
     apexchart: VueApexCharts,
     storageSeeAll
    },
    data :function(){
    return{
      sharedrive:false,
      drive_use:0,
      toppersonprivate:[],
      checkshowtable:"",
      openseeall:false,
      tab:null,
      heigthchart:100,
      percent_trash:0,
      percent_remain:0,
      show_em_use:'0',
      show_inbox_use:'0',
      show_outbox_use:'0',
      show_share_use:'0',
      topdepart:[],
      percentstorage:0,
      topperson:[],
       loaddataprogress:true,
       loaddataprogress_2:true,
       loaddataprogress_3:true,
       loaddataprogress_4:true,
      size: 50,
      page: 1,
      drive_tash:0,
      total_filefolder:0,
      total_file:0,
      total_folder:0,
      total_person:0,
      total_storage:'',
      remain_storage:'',
      used_storage:'',
      seriesFile :[0,0],
      series:[0,0,0,0],
          chartOptionsFile: {        
            labels: ["Files", "Folder"],
            theme: {
                  palette: 'palette5' 
                }
            // chart: {
            //   type: 'pie',                      
            // },
            // responsive: [{
             
            //   options: {
            //     chartFile: {
            //       width:50
            //     },
            //     legend: {
            //       position: 'bottom'
            //     }
            //   }
            // }]
          },
          chartOptionsFile_th: {        
            labels: ["ไฟล์", "โฟลเดอร์"],
            theme: {
                  palette: 'palette5' 
                }
            // chart: {
            //   type: 'pie',                      
            // },
            // responsive: [{
             
            //   options: {
            //     chartFile: {
            //       width:50
            //     },
            //     legend: {
            //       position: 'bottom'
            //     }
            //   }
            // }]
          },

          chartOptions: {               
               labels: ["Personal Drive", "Inbox", "Outbox", "Business Drive"],
                theme: {
                  palette: 'palette2' 
                }

            // chart: {
            //   type: 'pie',            
            // },
            // responsive: [{
             
            //   options: {
            //     chart: {
            //       width:50
            //     },
            //     legend: {
            //       position: 'bottom'
            //     }
            //   }
            // }]
          },
          chartOptions_th: {               
               labels: ["ไดรฟ์ส่วนตัว", "อินบ็อกซ์", "เอ้าท์บ็อกซ์", "ไดรฟ์บริษัท"],
                theme: {
                  palette: 'palette2' 
                }},

      headers: [
        {
          text: "dashboard.name",
          align: "left",
          value: "staff_name",
          width: "100%",
          sortable: false
        },]
    }
    },
    computed: {
    ...mapState(["username", "authorize", "account_active", "service","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.rootfile.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    showdatafilter() {
      // console.log(search);
      return this.rootfile.filter(item => {
        return (
          item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },headerTable(){
      return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
    },headerPage(){
      // console.log(this.color.theme);

      return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
    },colorVapp(){
      return 'background:'+this.color.BG+';'
    },colorSort(){
      return 'color:'+this.color.alertText+';'
    },colorProgress(){
      return 'color:'+this.color.theme+';'
    }
 },created(){
   //window.addEventListener('beforeunload', this.someMethod)
   //this.loaddata();
 },
 methods:{
   
   someMethod(event){
      this.$router.push({ name: "myfiles" })
      //console.log("test");

    },
    //โหลดข้อมูล dashboard
   async fn_loaddata(){
       var payload ={
           account_admin:this.dataAccountId,
           business_id:this.dataAccountActive.business_info.business_id,
       }
       let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/dashboard_business/v2",
          payload,{
            headers: { Authorization: auth.code },
          }
        ) .then(response => {
          //console.log("respone",response.data.result.space_storage.avalible_storage);
           if (response.data.status === "OK") {
             this.loaddataprogress = false;
            // console.log(response.data);
              // this.total_filefolder = (response.data.result.count_file+response.data.result.count_folder);
              // this.total_file = response.data.result.count_file.toLocaleString();
              // this.total_folder = response.data.result.count_folder.toLocaleString();
              this.total_person = response.data.result.count_personnel;
              this.total_storage = sizeDataConverter.convertStorageToKbMbGb(response.data.result.storage_business.storage_business);
              this.remain_storage = sizeDataConverter.convertStorageToKbMbGb(response.data.result.storage_business.remain_storage_business);
             this.used_storage = sizeDataConverter.convertStorageToKbMbGb(response.data.result.space_storage.drive_used);
             this.drive_use= response.data.result.space_storage.drive_used
             this.drive_tash = sizeDataConverter.convertStorageToKbMbGb(response.data.result.space_storage.drive_trash);
              this.percentstorage = (response.data.result.space_storage.drive_used/response.data.result.storage_business.storage_business)*100;
            //  console.log(this.percentstorage);
             this.percent_remain = 100-this.percentstorage
             this.percent_trash = (response.data.result.space_storage.drive_trash/response.data.result.space_storage.drive_used)*100;
            
            this.fn_loaddata_2();
             
              
              

             

             
           }else{
              this.loaddataprogress = false;

              Toast.fire({
            icon: "error",
            title: response.data.errorCode + ': ' + response.data.errorMessage
          });

            }
        }).catch(error => {
           this.loaddataprogress = false;
           Toast.fire({
            icon: "error",
            title: error,
          });
          //console.log("error",error);
        });
    },
   async fn_loaddata_2(){
       var payload ={
           account_admin:this.dataAccountId,
           business_id:this.dataAccountActive.business_info.business_id,
       }
       let auth = await gbfGenerate.generateToken();
       this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/dashboard_space_storage",
          payload,{
            headers: { Authorization: auth.code },
          }
        ) .then(response => {
          //console.log("respone",response.data.result.space_storage.avalible_storage);
           if (response.data.status === "OK") {
             this.loaddataprogress_2   = false;
            
             // ข้อมูลใส่กราฟ
             this.total_filefolder = (response.data.result.count_file+response.data.result.count_folder);
              this.total_file = response.data.result.count_file.toLocaleString();
              this.total_folder = response.data.result.count_folder.toLocaleString();
            this.seriesFile = [response.data.result.count_file,response.data.result.count_folder]
             this.series = [parseFloat(sizeDataConverter.convertStorageToGbnumber(response.data.result.storage_usage.storage_employee_drive)),
                            parseFloat(sizeDataConverter.convertStorageToGbnumber(response.data.result.storage_usage.storage_inbox)),
                            parseFloat(sizeDataConverter.convertStorageToGbnumber(response.data.result.storage_usage.storage_outbox)),
                            parseFloat(sizeDataConverter.convertStorageToGbnumber(response.data.result.storage_usage.storage_business_drive))]
          
            this.show_em_use = sizeDataConverter.convertStorageToKbMbGb(response.data.result.storage_usage.storage_employee_drive);
            this.show_inbox_use = sizeDataConverter.convertStorageToKbMbGb(response.data.result.storage_usage.storage_inbox);
            this.show_outbox_use = sizeDataConverter.convertStorageToKbMbGb(response.data.result.storage_usage.storage_outbox);
            this.show_share_use = sizeDataConverter.convertStorageToKbMbGb(response.data.result.storage_usage.storage_business_drive);

            // if(this.show_em_use == '0.00 GB' && this.show_inbox_use == '0.00 GB'&& this.show_outbox_use == '0.00 GB' && this.show_share_use  == '0.00 GB' ){

            //   this.show_em_use = sizeDataConverter.convertStorageToMb(response.data.result.storage_usage.storage_employee);
            //   this.show_inbox_use = sizeDataConverter.convertStorageToMb(response.data.result.storage_usage.storage_inbox);
            //   this.show_outbox_use = sizeDataConverter.convertStorageToMb(response.data.result.storage_usage.storage_outbox);
            //   this.show_share_use = sizeDataConverter.convertStorageToMb(response.data.result.storage_usage.storage_business_drive);
            // }

            // if(this.show_em_use == '0.00 MB' && this.show_inbox_use == '0.00 MB'&& this.show_outbox_use == '0.00 MB' && this.show_share_use  == '0.00 MB'){
            //     this.show_em_use = sizeDataConverter.convertStorageToKb(response.data.result.storage_usage.storage_employee);
            //     this.show_inbox_use = sizeDataConverter.convertStorageToKb(response.data.result.storage_usage.storage_inbox);
            //     this.show_outbox_use = sizeDataConverter.convertStorageToKb(response.data.result.storage_usage.storage_outbox);
            //     this.show_share_use = sizeDataConverter.convertStorageToKb(response.data.result.storage_usage.storage_share_file);
            //   }
            // this.fn_loaddata_3();
            this.fn_loaddata_4();
              }else{
              this.loaddataprogress_2 = false;

              Toast.fire({
            icon: "error",
            title: response.data.errorCode + ': ' + response.data.errorMessage
          });

            }
        }).catch(error => {
           this.loaddataprogress_2 = false;
           Toast.fire({
            icon: "error",
            title: error,
          });
          //console.log("error",error);
        });
    },
   async fn_loaddata_3(){
      var payload ={
           account_admin:this.dataAccountId,
           business_id:this.dataAccountActive.business_info.business_id,
       }
       let auth = await gbfGenerate.generateToken();
       this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/dashboard_department",
          payload,{
            headers: { Authorization: auth.code },
          }
        ) .then(response => {
          //console.log("respone",response.data.result.space_storage.avalible_storage);
           if (response.data.status === "OK") {
             this.loaddataprogress_3 = false;

              // top 5 แผนกที่ใช้ storage สูงสุด
             for(let i = 0 ;i<response.data.result.top_five_department.length;i++){
               let data = {}
               data["list"] = i+1
               data["department_name"] = response.data.result.top_five_department[i]["department_name"]
               data["folder_id"] = response.data.result.top_five_department[i]["my_folder_id"]
               data["used_storage"] = sizeDataConverter.convertStorageToKbMbGb(response.data.result.top_five_department[i]["used_storage"])
               data["storage_department"] = sizeDataConverter.convertStorageToKbMbGb(response.data.result.top_five_department[i]["storage_department"])
               data["percent_storage"] = this.calPercent(response.data.result.top_five_department[i]["used_storage"],this.drive_use)
               this.topdepart.push(data);
             }
              //console.log(this.topdepart);
              this.fn_loaddata_4();
           }else{
              this.loaddataprogress_3 = false;

              Toast.fire({
            icon: "error",
            title: response.data.errorCode + ': ' + response.data.errorMessage
          });

            }
        }).catch(error => {
           this.loaddataprogress_3 = false;
           Toast.fire({
            icon: "error",
            title: error,
          });
         // console.log("error",error);
        });
    },
   async fn_loaddata_4(){

      var payload ={
           account_admin:this.dataAccountId,
           business_id:this.dataAccountActive.business_info.business_id,
           type: "employee",
           limit: 5,
           offset: 0,
           topFive:"True"
       }
       let auth = await gbfGenerate.generateToken();
       this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/get_employees_and_department_for_dashborad",
          payload,{
            headers: { Authorization: auth.code },
          }
        ) .then(response => {
          //console.log("respone",response.data.result.space_storage.avalible_storage);
           if (response.data.status === "OK") {
             this.loaddataprogress_4 = false;
             // top 5 พนักงานที่ใช้ storage private สูงสุด
             if(response.data.result.used_storage_in_my_drive === 0){
               this.toppersonprivate = [];
             }else{
             for(let i = 0 ;i<response.data.result.used_storage_in_my_drive.length;i++){
               let dataperson = {}
               dataperson["list"] = i+1
               dataperson["name_eng"] = response.data.result.used_storage_in_my_drive[i]["fullname_eng"]
               dataperson["name_th"] = response.data.result.used_storage_in_my_drive[i]["fullname_th"]
               dataperson["used_storage"] = sizeDataConverter.convertStorageToKbMbGb(response.data.result.used_storage_in_my_drive[i]["used_storage"])
               dataperson["percent_storage"] = this.calPercent(response.data.result.used_storage_in_my_drive[i]["used_storage_in_business_byte"],this.drive_use);
              // console.log(",", dataperson["used_storage"]);
               this.toppersonprivate.push(dataperson);
             }
             }

             // top 5 พนักงานที่ใช้ storage business สูงสุด
            //  if(response.data.result.personnel_in_business_top_5[0]["fullname_eng"] === undefined){
            //     this.topperson=[];
            //  }else{
            //  for(let i = 0 ;i<response.data.result.personnel_in_business_top_5.length;i++){
            //    let dataperson = {}
            //    dataperson["list"] = i+1
            //    dataperson["name_eng"] = response.data.result.personnel_in_business_top_5[i]["fullname_eng"]
            //    dataperson["name_th"] = response.data.result.personnel_in_business_top_5[i]["fullname_th"]
            //    dataperson["used_storage"] = sizeDataConverter.convertStorageToKbMbGb(response.data.result.personnel_in_business_top_5[i]["used_storage_in_business_byte"])
            //    dataperson["percent_storage"] = this.calPercent(response.data.result.personnel_in_business_top_5[i]["used_storage_in_business_byte"],this.drive_use);
            //    console.log(",", dataperson["used_storage"]);
            //    this.topperson.push(dataperson);
            //  }
            //  }
             //console.log(this.topperson);
            
             }else{
              this.loaddataprogress_4 = false;

              Toast.fire({
            icon: "error",
            title: response.data.errorCode + ': ' + response.data.errorMessage
          });

            }
        }).catch(error => {
           this.loaddataprogress_4 = false;
           Toast.fire({
            icon: "error",
            title: error,
          });
          //console.log("error",error);
        });
    },
    calPercent(scrap,part){
       let result;
       if(scrap === 0 || part === 0){
         result = 0
       }else{
         result = (scrap/part)*100
       }

       return result
       
    }

 },mounted(){
   this.fn_loaddata();
 }
}
</script>
<style>
.pointer {
  cursor: pointer;
}
#thiscontainerdashboardadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>
